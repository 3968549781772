@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Open Sans", sans-serif !important;
}

div,
span,
small,
label {
  font-family: "Roboto", "helvetica", sans-serif !important;
}

img {
  max-width: 100%;
  display: block;
}
