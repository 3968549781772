@use "styles/index" as *;

.newspaper {
  margin-bottom: 72px;
  border: 1px solid $neutral-300;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-bottom: 40px;
  }

  &-first {
    flex-direction: row;

    .newspaper-img {
      order: 2;
      width: 50%;
      min-height: 288px;

      img,
      video {
        border: 0;
        display: block;
      }
    }

    .newspaper-content {
      padding: 40px;
      width: 50%;

      @include tablet {
        padding: 20px;
      }

      p {
        margin-bottom: 20px;
      }

      &__desc {
        line-height: 22px;
        font-size: 18px;
        color: $neutral-900;
        display: block;
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: $shadow-s;
  }

  &-img {
    width: 100%;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-bottom: 1px solid $neutral-300;
      display: block;
    }
  }

  &-content {
    padding: 20px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
      padding: 16px;
    }

    p {
      font-size: 20px;
      color: $neutral-700;
      font-weight: 700;
      line-height: 25px;

      @include desktop {
        font-size: 18px;
      }

      @include mobile {
        font-size: 16px;
      }
    }

    &__desc {
      display: none;

      &.firstNews {
        display: block;
      }

      @include for-mobile-down {
        display: block;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .newspaper-category {
        font-size: 11px;
        padding: 6px 12px;
        border-radius: 12px;
        font-weight: 500;

        &__success {
          background-color: $success-150;
          color: $success-600;
        }

        &__secondary {
          background-color: $secondary-150;
          color: $secondary-500;
        }

        &__info {
          background-color: $info-150;
          color: $info-500;
        }

        &__warning {
          background-color: $warning-150;
          color: $warning-500;
        }
      }

      .newspaper-time {
        font-size: 12px;
        color: $neutral-500;
      }
    }
  }
}
