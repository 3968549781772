.loading {
  display: none;

  &-open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
