@use "styles/index" as *;

.header {
  &-logo {
    width: 40px;
    height: 40px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    @include desktop {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-toolbar {
    padding: 0;
    justify-content: center;

    @include desktop {
      padding: 0 20px;
      justify-content: flex-start;
    }

    &__tablet {
      display: none;

      @include desktop {
        display: block;
      }
    }

    &__navbar {
      display: flex;

      @include desktop {
        display: none;
      }

      .navbar-readAll {
        &:hover {
          color: $info-700;
        }
      }
    }
  }

  &-list {
    display: flex;
    font-size: 13.5px;

    @include desktop {
      font-size: 15px;
    }

    &__item {
      &:hover {
        text-decoration: underline;
      }

      &:nth-last-of-type(-n + 3) {
        @media (max-width: 1300px) {
          display: none;
        }
      }
    }
  }
}
