@use "styles/index" as *;

.wrapper-container {
  padding: 0 70px;

  @include desktop {
    padding: 0;
  }

  @include tablet {
    padding: 0 12px;
  }

  @include for-mobile-down {
    padding: 0 4px;
  }
}
