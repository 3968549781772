@use "./variables" as *;

@mixin for-mobile-down {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$screen-sm-max + 1}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$screen-md-max + 1}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$screen-lg-max + 1}) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: #{$screen-lg-max + 1}) {
    @content;
  }
}
