@use "styles/index" as *;

.homePage {
  padding: 40px 0;

  &-title {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-bottom: 40px;

    h1 {
      font-size: 48px;
      color: $info-700;
      font-weight: 700;
      text-transform: capitalize;
      padding: 0 30px;
      background-color: white;
      position: relative;
      z-index: 2;
      display: inline-block;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $info-700;
      z-index: 1;
    }

    @include mobile {
      margin-bottom: 20px;

      h1 {
        font-size: 36px;
        padding: 0 16px;
      }
    }
  }

  &-load {
    button {
      width: 32%;
    }
  }
}
